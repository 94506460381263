import { API_URL } from 'utils/constants';
import client from './client';

export function getLibraries() {
  return client.get(`${API_URL}/api/v1/libraries.json`, { withCredentials: true })
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}
