import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { mainReducer } from './reducers';

const rootReducer = combineReducers({
  main: mainReducer
});

const initialState = {
  main: {
    currentUser: null,
    csrfToken: null,
  }
};

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
);

export default store;
