import styled from 'styled-components'

const Book = styled('div')`
  text-align: center;
  width: 160px;
  border: 1px solid #000;
  padding: 8px;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .Image {
    position: relative;
    padding-top: 150%;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      // object-fit: cover
    }
  }

  .Description {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .Title {
      font-weight: bold;
    }
  }
`

const Books = ({ books, setSelectedBookId }) => {
  return (
    <div>
      <div className='Search-Information'>
        Колькасьць: {books.length}
      </div>
      <div className='Books'>
        {
          books.map(({ id, attributes: { title, author, year, file_url, cover_url }}) => {
            return (
              <Book key={id}>
                <div>
                  <div className="Image">
                    <img src={cover_url}/>
                  </div>
                  <div className="Description">
                    <div className="Title">{title}</div>
                    <div className="Author">{author}</div>
                    {
                      year && (
                        <div className="Year">{year}</div>
                      )
                    }
                    <div className="id" onClick={() => setSelectedBookId(id)}>[{id}]</div>
                    {
                      file_url && (
                        <a href={file_url}>Чытаць</a>
                      )
                    }
                  </div>
                </div>
              </Book>
            )
          })
        }
      </div>
    </div>
  )
}

export default Books
