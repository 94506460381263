import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { login } from 'api/sessions';
import { setCurrentUser } from 'store/actions';
import styled from 'styled-components';

const Container = styled.div`
  .login {
    height: 80vh;
    align-items: center;
    justify-content: center;
    display: flex;

    .title {
      display: flex;
      gap: 10px;
      margin-bottom: 28px;

      h3 {
        margin: 0;
        font-size: 18px;
      }
    }

    .Form {
      display: flex;
      flex-direction: column;
      // box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.15);
      // border: 1px solid #ccc;
      // padding: 30px;

      gap: 24px;

      .form-control {
        display: flex;
        flex-direction: column;

        label {
          padding-bottom: 4px;
        }

        input {
          // outline: none;
          text-align: left;
          border: 1px solid #0A1229;                                                                        ;
          font-size: 16px;
          padding: 0 15px;
          width: 280px;
          height: 46px;
          margin-top: 8px;
          // background-color: #eeeefc;

          ::placeholder {
            color: #0A1229;
          }

          &:focus {
            border: 1px solidrgb(46, 128, 50);
            background-color: #EEF3FB;
            outline-color:rgb(94, 95, 33);
            outline-width: inherit;
          }
        }

        .login-button {
          cursor: pointer;

          // background-color: #fff;
          color: #FCFEFE;
          background-color:rgb(83, 92, 41);
          border: none;

          width: 160px;
          text-align: center;

          &:hover {
            background-color:rgb(102, 101, 36);
          }

          &:focus {
            background-color:rgb(86, 104, 37);
          }
        }
      }


    }
  }
`;


const LoginPage = (props) => {
  const {
    currentUser,
    setCurrentUser,
    previousPath,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const onSubmit = () => {
    login({
      user: {
        email, password
      }
    }).then(({ data, status, errors }) => {
      if (errors) {
        // errors.forEach(({ text }) => {
        //   showError(text)
        // })
      } else {
        setCurrentUser(data.user)
        navigate('/')
        // setPreviousPath(null)
        // data.messages.forEach(({ text }) => showSuccess(text))
      }
    })
  }

  return (
    <Container>
      <div className='login'>
        <div className='container'>
          <div className='title'>
            {/* <img src='/profile.svg' alt="profile"/> */}
            <h3>Увайсьці</h3>
          </div>
          <div className='Form'>
            <div className='form-control'>
              <label htmlFor='email'>
                Імя
              </label>
              <input
                id='email'
                placeholder='Імя'
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
                required
              />
            </div>
            <div className='form-control'>
              <label htmlFor='password'>
                Пароль
              </label>
              <input
                id='password'
                type='password'
                placeholder='Пароль'
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
                required
              />
            </div>
            <div className='form-control'>
              <input className='login-button' type='submit' value='Увайсьці' onClick={onSubmit}/>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
  })
)(LoginPage);
