import { BrowserRouter } from 'react-router-dom'

import { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { getBooks } from 'api/book'
import styled from "styled-components"
import { BookModal } from 'components/BookModal';
import SearchPanel from 'components/SearchPanel';
import Books from 'components/Books';
import { getLibraries } from 'api/library'

import 'font-awesome/css/font-awesome.css'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Books {
    flex-wrap: wrap;
    gap: 32px;
    display: flex;
  }

  .Search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    gap: 4px;

    > div {
      display: flex;
      justify-content: center;
      gap: 8px;
    }

    .Search-Filter {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

const HomePage = () => {
  const [books, setBooks] = useState([])
  const [filteredBooks, setFilteredBooks] = useState([])
  const [selectedBookId, setSelectedBookId] = useState(null)
  const [search, setSearch] = useState([])
  const [filter, setFilter] = useState({ withoutTitle: false, withoutVirtual: false, bookType: '', isInvert: false, libraryId: '' })
  const [libraries, setLibraries] = useState([])

  console.log('filterfilter',filter)

  useEffect(() => {
    getLibraries().then((response) => setLibraries(response))
  }, [])

  useEffect(() => {
    getBooks().then((response) => setBooks(response))
  }, [])

  useEffect(() => {
    if (books) {
      setFilteredBooks(books.filter(({ attributes: { title, author, year, book_type, is_virtual, library_id }}) => {
        const result = ([title, author, year].join(' ').includes(search) &&
          (filter['withoutTitle'] ? !title : true) &&
          (filter['withoutVirtual'] ? !is_virtual : true) &&
          (filter['bookType'] ? book_type == filter['bookType'] : true) &&
          (filter['libraryId'] ? library_id == filter['libraryId'] : true)
        )

        return filter['isInvert'] ? !result : result
      }))
    }
  }, [books, search, filter])

  return (
    <Container>
      <SearchPanel filter={filter} setFilter={setFilter} search={search} setSearch={setSearch} libraries={libraries}/>
      <Books books={filteredBooks} setSelectedBookId={setSelectedBookId}/>
      {
        selectedBookId && <BookModal id={selectedBookId} setId={setSelectedBookId} setBooks={setBooks} libraries={libraries}/>
      }
    </Container>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
  })
)(HomePage);
