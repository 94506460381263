import { useState, useContext } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import cx from 'classnames'
import { API_URL } from 'utils/constants'

import { logout, getCurrentUser } from 'api'
import { setCurrentUser, setPreviousPath } from 'store/actions';
// import { MessageContext } from 'App'

const Container = styled('div')`
  .navbar {
    display: flex;
    justify-content: space-between;
  }

  .form-inline {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .form-control {
      margin-right: 5px;
    }
  }

  .nav-item:not(.active) {
    &:hover {
      .badge {
        background-color: #c00;
      }
    }
  }

  .nav-item {
    position: relative;

    a {
      display: flex;
      gap: 4px;
    }
  }

  .badge {
    background-color: #f00;
    border-radius: 12px;
    padding: 1px 4px;
    color: #fefefe;
    font-size: 0.8em;
    font-weight: bold;
    height: 15px;
    transition: none;
  }
`

const Menu = ({ currentUser, setCurrentUser, setPreviousPath }) => {
  const location = useLocation()
  const { hash, pathname, search } = location
  const navigate = useNavigate()

  // const { showSuccess, showError } = useContext(MessageContext)

  const handleLogout = () => {
    logout().then(({ data, errors }) => {
      if (errors) {
        errors.forEach(({ text }) => {
          // showError(text)
        })
      } else {
        setCurrentUser(null)
        navigate('/login')
        setPreviousPath(null)
        // data.messages.forEach(({ text }) => showSuccess(text))
      }
    })
  }

  return (
    <Container>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        {/* <Navbar.Brand href="/">Галоўная</Navbar.Brand> */}
        {/* <ul className='navbar-nav' to="/personal">
          <li className='nav-item'>
            <Link to='/' className={cx('nav-link', { active: new RegExp(`^/$`).test(pathname)})}>Галоўная</Link>
          </li>
        </ul> */}
        {/* <Navbar aria-controls="navbarSupportedContent" /> */}
        {/* <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div id='navbarSupportedContent' className='collapse navbar-collapse'> */}
          {/* <ul className='navbar-nav mr-auto mt-2 mt-lg-0'> */}
            {/* <ul className='navbar-nav' to="/people">
              <li className={cx('nav-item', { active: new RegExp(`/people`).test(pathname)})}><Link to='/people' className='nav-link'>Людзі</Link></li>
            </ul>
            <li className={cx('nav-item', { active: new RegExp(`/photos`).test(pathname)})}><Link to="/photos" className='nav-link'>Фотаздымкі</Link></li>
            <li className={cx('nav-item', { active: new RegExp(`/documents`).test(pathname)})}><Link to="/documents" className='nav-link'>Дакументы</Link></li>
            <li className={cx('nav-item', { active: new RegExp(`/notes`).test(pathname)})}><Link to="/notes" className='nav-link'>Заўвагі</Link></li> */}
          {/* </ul> */}
          <ul className='navbar-nav ml-auto'>
          </ul>
          <ul className='navbar-nav ml-auto'>
            {
              // currentUser.roles?.find(({ name }) => name === 'admin') && (
              //   <li className='nav-item'><Link onClick={(() => window.location.href = `${API_URL}/admin` )} className='nav-link'>Адмінка</Link></li>
              // )
            }
            {/* <li className='nav-item'><Link to="/settings/permissions"className='nav-link'>Налады</Link></li> */}
            <li className='nav-item'><Link className='nav-link'onClick={handleLogout}>Выйсці</Link></li>
          </ul>
        {/* </div> */}
      </nav>
    </Container>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
  })
)(Menu);
