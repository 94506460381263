import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux';

import { setCurrentUser } from './store/actions';
import Layout from 'components/common/Layout';

const App = ({ currentUser }) => {
  return (
    <Router>
      {/* <MessageContext.Provider value={messageContextValue}> */}
        {/* <ToastContainer newestOnTop/> */}
        <Layout/>
      {/* </MessageContext.Provider> */}
    </Router>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
  })
)(App);
