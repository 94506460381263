import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'utils/constants';
import client from './client';

export function getBook(id) {
  return client.get(`${API_URL}/api/v1/books/${id}.json`, { withCredentials: true })
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);

      return null;
    })
}

export function getBooks() {
  return client.get(`${API_URL}/api/v1/books.json`, { withCredentials: true })
    .then(response => response.data.data)
    .catch((response) => {
      console.log(response);

      return [];
    })
}

export function createBook(params, config = {}) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/books.json`, params, { headers: { 'X-CSRF-TOKEN': token, withCredentials: true }, ...config })
    .then(response => response.data.data)
}

export function updateBook(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/books/${id}.json`, params, { headers: { 'X-CSRF-TOKEN': token, withCredentials: true } })
    .then(response => response.data.data)

}

export function deleteBook(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/books/${id}.json`, { headers: { 'X-CSRF-TOKEN': token, withCredentials: true } })
    .then(response => response.data.data)
}
