const SearchPanel = ({ filter, setFilter, search, setSearch, libraries }) => {
  console.log('filter', filter)
  
  return (
    <div className='Search'>
      <div className='Search-Input'>
        <input value={search} onChange={(e) => setSearch(e.target.value)}></input>
      </div>
      <div className='Search-Filter'>
        <input id='title' type='checkbox' value={filter['withoutTitle']} onChange={(e) => setFilter({ ...filter, withoutTitle: e.target.checked })}/>
        <label htmlFor='title'>Бяз назваў</label>
        <input id='virtual' type='checkbox' value={filter['withoutVirtual']} onChange={(e) => setFilter({ ...filter, withoutVirtual: e.target.checked })}/>
        <label htmlFor='virtual'>Бяз віртуальных</label>
        <select if='periodic' value={filter['bookType']} onChange={(e) => setFilter({ ...filter, bookType: e.target.value })}>
          <option value=''>Усе выданьні</option>
          <option value='periodic'>Перыядычныя</option>
          <option value='non_periodic'>Не перыядычныя</option>
        </select>
        <select value={filter['libraryId']} onChange={(e) => setFilter({ ...filter, libraryId: e.target.value })}>
          <option value=''>Усе бібліятэкі</option>
          {
            libraries.map(({ id, attributes: { name }}) => (
              <option key={id} value={id}>{name}</option>
            ))
          }
        </select>
        <input id='is_invert' type='checkbox' value={filter['is_invert']} onChange={(e) => setFilter({ ...filter, isInvert: e.target.checked })}/>
        <label htmlFor='is_invert'>Інвертаваць</label>
      </div>
    </div>
  )
}

export default SearchPanel
