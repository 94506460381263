import { useState, useEffect } from 'react';
import { Modal } from 'components/common/Modal';
import { SpinnerCircular } from 'spinners-react'
import styled from 'styled-components'

import TextField from './common/TextField'
import Textarea from './common/Textarea'

import { getBook, updateBook, deleteBook } from 'api/book'

const Container = styled.div`
  label {
    margin: 0;
  }

  .Tools {
    display: flex;

    &-Item {
      flex-grow: 1;
    }
  }

  .TagsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    row-gap: 0;
  }

  .Tags {
    display: flex;

    button {
      padding: 0;
      border: none;
      background: none;
      color: #007bff;
    }

    .Actions {
      display: flex;
      gap: 4px;
    }

    .Tag__Selected {
      color: #0a0;
      transform: scale(1.5);
    }
  }

  .image {
    #img-mapper {
      min-width: 100%;
    }
  }

  .img-mapper-img {
    width: 100%;
  }

  .Button {
    border: 1px solid #c0c0c0;
    padding: 12px;
    width: 100%;
    background-color: #007dff;
    color: #fefefe;
  }
`

export const BookModal = ({ id, setId, setBooks, libraries }) => {
  const [book, setBook] = useState(null)

  const onRequestClose = () => {
    setId(null)
  }

  const onChangeByName = (name) => (e) => setBook({ ...book, [name]: e.value ?? (e.target.type == 'checkbox' ? e.target.checked : e.target.value)})

  useEffect(() => { getBook(id).then(setBook) }, [id])

  const onBookDelete = () => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць кнігу?`)) {
      deleteBook(book.id).then((response) => {
        // showSuccess('Фотаздымак пасьпяхова выдалены')

        setBooks((books) => books.filter(({ id: bookId }) => bookId != id))
        setId(null)
      }).catch((respose) => {
        // showError()
      })
    }
  }

  const onSumbit = () => {
    const params = { title: book?.title, author: book?.author, year: book?.year, description: book?.description, book_type: book?.book_type, is_virtual: book?.is_virtual, library_id: book?.library_id }

    updateBook(book?.id, params).then((book) => {
      setBooks((books) => {
        const index = books.findIndex(({ id: bookId }) => bookId == id)

        return [
          ...books.slice(0, index),
          {
            ...books[index],
            attributes: {
              ...books[index].attributes,
              title: book?.title,
              author: book?.author,
              year: book?.year,
              book_type: book?.book_type,
              is_virtual: book?.is_virtual,
              library_id: book?.library_id,
            }
          },
          ...books.slice(index + 1)
        ]
      })

      setId(null)
    }).catch((respose) => {
    })
  }

  return <Modal
    isOpen={id ? true : false}
    contentLabel='Рэдагаваньне фотаздымка'
    onRequestClose={onRequestClose}
  >
    {
      book ? (
        <Container>
          <div className="Tools">
            <div></div>
            {
              <button className="btn Tools-Item" rel="nofollow" onClick={onBookDelete}>
                <i className="fa fa-trash"></i>
              </button>
            }
          </div>
          <hr/>
          <br/>
          <TextField label={'Назва'} value={book?.title || ''} onChange={onChangeByName('title')}/>
          <TextField label={'Аўтар'} value={book?.author || ''} onChange={onChangeByName('author')}/>
          <TextField label={'Дата'} value={book?.year || ''} onChange={onChangeByName('year')}/>
          <Textarea label={'Апісаньне'} value={book?.description || ''} onChange={onChangeByName('description')}/>
          <select if='periodic' value={book?.book_type} onChange={onChangeByName('book_type')}>
            <option value='periodic'>Перыядычныя</option>
            <option value='non_periodic'>Не перыядычныя</option>
          </select>
          <div>
            <label htmlFor='is_virtual' className="form-control-label string optional">
              Віртуальная
            </label>
            <input id='is_virtual' type='checkbox' checked={String(book?.is_virtual) == 'true'} onChange={onChangeByName('is_virtual')}/>
          </div>
          <select value={book?.library_id} onChange={onChangeByName('library_id')}>
            {
              libraries.map(({ id, attributes: { name }}) => (
                <option value={id}>{name}</option>
              ))
            }
          </select>
          <hr/>
          <button className="Button btn btn btn-default" onClick={onSumbit}>Захаваць</button>
        </Container>
      ) : (
        <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
      )
    }
  </Modal>
}
