import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { SpinnerCircular } from 'spinners-react'
import styled from 'styled-components'

import {
  LoginPage,
  HomePage,
} from 'pages'

import Menu from 'components/common/Menu'
import { setCurrentUser } from 'store/actions';
import { getCurrentUser } from 'api'
import { PUBLIC_PATHS, loadingStatuses } from 'utils/constants';

const Container = styled('div')`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

const Layout = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  // const { showError } = useContext(MessageContext)
  const {
    currentUser,
    setCurrentUser,
    // setPreviousPath,
  } = props;

  const [loadingStatus, setLoadingStatus] = useState(loadingStatuses.PENDING);

  const redirectToPublic = (path = '/login') => {
    if (!PUBLIC_PATHS.includes(location.pathname)) {
      // setPreviousPath(location.pathname)
      navigate(path)
    }
  }

  useEffect(() => {
    getCurrentUser().then(({ data, message, errors }) => {
      if (data?.user) {
        setCurrentUser(data.user)
        // setPreviousPath(null)
      } else {
        // (errors ?? []).forEach(({ message }) => {
        //   showError(message)
        // })
        redirectToPublic()
      }
    }).finally(() => {
      setLoadingStatus(loadingStatuses.LOADED)
    })
  }, [])

  switch(loadingStatus) {
    case loadingStatuses.LOADED: {
      return (
        <div>
          {
            currentUser && <Menu />
          }
          <Container>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage/>} />
            </Routes>
          </Container>
        </div>
      )
    }
    default: {
      return (
        <div className='spinner'>
          <SpinnerCircular size={50} thickness={180} speed={280} color="rgba(0, 0, 0, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      )
    }
  }
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
    // setPreviousPath: (path) => dispatch(setPreviousPath(path)),
  })
)(Layout);
